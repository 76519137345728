export const meta: BlockMeta = {
  editMode: "both",
  title: "Marquee",
  description: "",
  keywords: ["marquee"],
  icon: "images-alt",
  cache: false,
  tags: ["root"],
}

import { defineBlock, EditableText } from "eddev/blocks"
import Marquee from "react-fast-marquee"
import { SVGLogo } from "@components/atoms/SVGLogo"

export default defineBlock("content/marquee", (props) => {
  return (
    <div className={`subtheme-${props.scheme}`}>
      <div className="w-full h-full flex justify-center bg-bg">
        <div className="grid-auto w-full relative items-center py-10 md:py-[80px]">
          <div className="col-span-12">
            <EditableText
              className="type-title-xs text-text w-full text-center pb-9"
              store="title"
              placeholder="Enter title..."
            />
          </div>

          <div className="col-span-12 overflow-hidden relative">
            {props.logos && (
              <Marquee speed={50}>
                <div className="flex justify-center items-center">
                  {props.logos.map((logo, i) => (
                    <SVGLogo
                      className="text-text w-auto max-h-[75px] px-9"
                      key={i}
                      logoUrl={logo?.logo?.mediaItemUrl ?? ""}
                    />
                  ))}
                </div>
              </Marquee>
            )}
          </div>
        </div>
      </div>
    </div>
  )
})
